import axios from "@axios";
import axiosIns from "@/libs/axios";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchUsersExport(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/staff/export", {'params' : data, responseType: 'blob'} )
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/staff/list", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`/staff/?id=${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post("/staff", { user: userData })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("/staff", userData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    deleteUser(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .delete(`/staff`, { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchGenders(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get("/options?gender=1")
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchTeams(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get("/options?team=1")
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchRoles(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get("/options?role=1")
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchLicenseAuthorities(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get("/options?license_authority=1")
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchLicenseTypes(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get("/options?license_type=1")
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchUserStatus(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get("/options?user_status=1")
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchEmployerCompany(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get("/options?employer_company=1")
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    setUserRole(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("/role/setUserRole", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchRole(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/role", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchOptions(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`/options?${queryParams}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchCourseRecord(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`/course_records_staff/list/`, { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateApprovalBit(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("/course_records_staff", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addNewCourseRecord(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("/course_records", data, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateCourseRecord(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("/course_records", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    getAllCourseRecord(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/course_records/list", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchCourse(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`/course_records/?id=${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchProgress(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`/course/setting/progress?id=${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchHours(ctx, { year, authority_id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(
            `/course/setting/hours?year=${year}&authority_id=${authority_id}`
          )
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    uploadCertificate(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("/course_records/image", data, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    deleteCertificate(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .delete("/course_records/image", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addStaffToCertificate(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post("/course/staff_record", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    deleteStaffRecord(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .delete("/course/staff_record", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    getCourseList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/course/setting/list")
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      })
    },
    exportCourseRecord(ctx,data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("/course_records/export",data, {responseType: 'blob'} )
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    
  },
};
