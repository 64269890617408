<template>
  <div id="user_form" v-if="userData">
    <div class="d-flex justify-content-between align-items-center">
      <h3 class="mb-0 ml-0 color4 font-weight-bolder">
        {{ userData.id == 0 ? "ADD" : "EDIT" }} User
      </h3>

      <!-- <b-row> -->
      <!-- <b-col cols="12" md="6"> -->
      <b-form-group label-for="is_for_client">
        <b-form-checkbox
          :checked="userData.is_for_client ? true : false"
          @change="userData.is_for_client = !userData.is_for_client"
          class="custom-control-success"
          name="check-button"
          switch
          inline
        />
        {{
          userData.is_for_client ? "Account for Client" : "Account for Client"
        }}
      </b-form-group>
      <!-- </b-col> -->
      <!-- </b-row> -->
    </div>

    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-overlay
        :show="loading"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
      >
        <!-- Form: Personal Info Form -->
        <b-form class="mt-1" @submit.prevent="handleSubmit(onSubmit)">
          <b-row class="mt-2 mt-sm-3">
            <!-- Field: User ID -->
            <b-col cols="12" md="6">
              <!-- <validation-provider
              #default="validationContext"
              name="User No."
              rules="required"
            > -->
              <b-form-group
                label="User ID"
                label-for="id"
                label-cols-sm="3"
                label-cols="6"
              >
                <div class="d-flex h-100 align-items-center">
                  {{ userData.id == 0 ? `${userData.next_id}` : userData.id }}
                  <!-- <div v-if="userData.id == 0">{{ userData.next_id }}</div> -->
                  <!-- <div v-else>{{ userData.id }}</div> -->
                </div>
                <!-- <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback> -->
              </b-form-group>
              <!-- </validation-provider> -->
            </b-col>

            <!-- Field: Status -->
            <b-col cols="12" md="6">
              <validation-provider
                #default="validationContext"
                name="Status"
                rules="required"
              >
                <b-form-group
                  label="Status"
                  label-for="user_statuses_id"
                  label-cols-sm="3"
                  label-cols="12"
                >
                  <div class="form-col-select">
                    <v-select
                      v-model="userData.user_statuses_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="userStatusOptions"
                      label="status_name"
                      :reduce="(option) => option.id"
                      :clearable="false"
                      placeholder="Select status"
                    />
                    <feather-icon size="18" icon="ChevronDownIcon" />
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </div>
                </b-form-group>
              </validation-provider>
            </b-col>
            <hr class="w-100" />

            <!-- Field: Name -->
            <b-col cols="12" md="6">
              <validation-provider
                #default="validationContext"
                name="User Name (EN)"
                rules="required"
              >
                <b-form-group
                  class="required"
                  label="User Name (EN)"
                  label-for="name_en"
                  label-cols-sm="3"
                  label-cols="12"
                >
                  <b-form-input
                    id="name_en"
                    v-model="userData.name"
                    :state="getValidationState(validationContext)"
                    placeholder="Enter user's English name"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Field: Name (CN) -->
            <b-col cols="12" md="6">
              <validation-provider
                #default="validationContext"
                name="User Name (CN)"
                rules="required"
              >
                <b-form-group
                  class="required"
                  label="User Name (CN)"
                  label-for="name"
                  label-cols-sm="3"
                  label-cols="12"
                >
                  <b-form-input
                    id="name"
                    v-model="userData.name_cn"
                    :state="getValidationState(validationContext)"
                    placeholder="Enter user's Chinese name"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <hr class="w-100" />

            <!-- Field: ID Number -->
            <b-col cols="12" md="6">
              <validation-provider
                #default="validationContext"
                name="ID Number"
                rules="required"
              >
                <b-form-group
                  label="ID Number"
                  label-for="hk_id"
                  label-cols-sm="3"
                  label-cols="12"
                  class="required"
                >
                  <b-form-input
                    id="hk_id"
                    v-model="userData.hkid"
                    placeholder="Enter ID number"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Field: Date of Birth-->
            <b-col cols="12" md="6">
              <validation-provider
                #default="validationContext"
                name="Date of birth"
                rules="required"
              >
                <b-form-group
                  label-cols-sm="3"
                  label-cols="12"
                  class="required"
                  label="Date of birth"
                  label-for="date_of_birth"
                >
                  <flat-pickr
                    v-model="userData.date_of_birth"
                    class="form-control"
                    :config="flatDateConfigForBirth"
                    placeholder="YYYY-MM-DD"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <hr class="w-100" />

            <!-- Field: Passport Number-->
            <b-col cols="12" md="6">
              <!-- <validation-provider
                #default="validationContext"
                name="Passport Number"
                rules="required"
              > -->
              <b-form-group
                label-cols-sm="3"
                label-cols="12"
                label="Passport Number"
                label-for="passport_number"
              >
                <!-- class="required" -->
                <b-form-input
                  id="passport_number"
                  v-model="userData.passport_number"
                  placeholder="Enter passport number"
                />
                <!-- :state="getValidationState(validationContext)" -->

                <!-- <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback> -->
              </b-form-group>
              <!-- </validation-provider> -->
            </b-col>

            <!-- Field: Passport Expiry-->
            <b-col cols="12" md="6">
              <!-- <validation-provider
                #default="validationContext"
                name="Passport Expiry"
                rules="required"
              > -->
              <b-form-group
                label-cols-sm="3"
                label-cols="12"
                label="Passport Expiry"
                label-for="passport_expiry"
              >
                <!-- class="required" -->
                <flat-pickr
                  v-model="userData.passport_expiry"
                  class="form-control"
                  :config="flatDateConfig"
                  placeholder="YYYY-MM-DD"
                />
                <!-- <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback> -->
              </b-form-group>
              <!-- </validation-provider> -->
            </b-col>
            <hr class="w-100" />

            <!-- Field: Gender -->
            <b-col cols="12" md="6">
              <validation-provider
                #default="validationContext"
                name="Gender"
                rules="required"
              >
                <b-form-group
                  label-cols-sm="3"
                  label-cols="12"
                  class="required"
                  label="Gender"
                  label-for="gender_id"
                >
                  <div class="form-col-select">
                    <v-select
                      v-model="userData.gender_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="genderOptions"
                      :clearable="false"
                      label="name"
                      :reduce="(option) => option.id"
                      placeholder="Select gender"
                    />
                    <feather-icon size="18" icon="ChevronDownIcon" />
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </div>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Field: Contact No.-->
            <b-col cols="12" md="6">
              <validation-provider
                #default="validationContext"
                name="Contact No."
                rules="required"
              >
                <b-form-group
                  label-cols-sm="3"
                  label-cols="12"
                  class="required"
                  label="Contact No."
                  label-for="phone"
                >
                  <b-form-input
                    id="phone"
                    v-model="userData.contact_number"
                    placeholder="Enter contact number"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <hr class="w-100" />

            <!-- Field: Email Address -->
            <b-col cols="12" md="6">
              <validation-provider
                #default="validationContext"
                name="Email"
                rules="required"
              >
                <b-form-group
                  label-cols-sm="3"
                  label-cols="12"
                  class="required"
                  label="Email Address"
                  label-for="email"
                >
                  <b-form-input
                    id="email"
                    v-model="userData.email"
                    placeholder="Enter email address"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Field: Working Visa-->
            <b-col cols="12" md="6">
              <validation-provider
                #default="validationContext"
                name="Working Visa"
                rules="required"
              >
                <b-form-group
                  label-cols-sm="3"
                  label-cols="12"
                  class="required"
                  label="Working Visa"
                  label-for="working_visa"
                >
                  <div class="form-col-select">
                    <v-select
                      v-model="userData.working_visa"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="visaOptions"
                      :clearable="false"
                      label="text"
                      :reduce="(option) => option.value"
                      placeholder="Select working visa"
                    />
                    <feather-icon size="18" icon="ChevronDownIcon" />
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </div>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Field: Personal Email Address -->
            <b-col cols="12" md="6">
              <validation-provider
                #default="validationContext"
                name="Personal Email"
                rules="required"
              >
                <b-form-group
                  label-cols-sm="3"
                  label-cols="12"
                  class="required"
                  label="Personal Email Address"
                  label-for="personal_email"
                >
                  <b-form-input
                    id="personal_email"
                    v-model="userData.personal_email"
                    placeholder="Enter Personal email address"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <hr class="w-100" />

            <!-- Field: Role -->
            <b-col cols="12" md="6">
              <validation-provider
                #default="validationContext"
                name="Role"
                rules="required"
              >
                <b-form-group
                  label-cols-sm="3"
                  label-cols="12"
                  class="required"
                  label="Role"
                  label-for="role"
                >
                  <div class="form-col-select">
                    <v-select
                      v-model="userData.roles"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="roleOptions"
                      label="name"
                      :reduce="(option) => option.name"
                      :clearable="false"
                      placeholder="Select Role"
                    />
                    <feather-icon size="18" icon="ChevronDownIcon" />
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </div>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Field: Category -->
            <b-col cols="12" md="6">
              <validation-provider
                #default="validationContext"
                name="Category"
                rules="required"
              >
                <b-form-group
                  label-cols-sm="3"
                  label-cols="12"
                  class="required"
                  label="Category"
                  label-for="team_id"
                >
                  <div class="form-col-select">
                    <v-select
                      v-model="userData.teams"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="teamOptions"
                      :clearable="false"
                      label="en_name"
                      :reduce="(option) => option.id"
                      placeholder="Select Category"
                      multiple
                    />
                    <feather-icon size="18" icon="ChevronDownIcon" />
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </div>
                </b-form-group>
              </validation-provider>
            </b-col>
            <hr class="w-100" />

            <!-- Field: Date of Joining -->
            <b-col v-if="!userData.is_for_client" cols="12" md="6">
              <b-form-group
                label-cols-sm="3"
                label-cols="12"
                label="Day of Joining"
                label-for="day_of_employment"
              >
                <flat-pickr
                  v-model="userData.onboard"
                  class="form-control"
                  :config="flatDateConfig"
                  placeholder="YYYY-MM-DD"
                />
              </b-form-group>
            </b-col>

            <!-- Field: Employer Company -->
            <b-col v-if="!userData.is_for_client" cols="12" md="6">
              <b-form-group
                label-cols-sm="3"
                label-cols="12"
                label="Principal"
                label-for="employer_company"
              >
                <div class="form-col-select">
                  <v-select
                    v-model="userData.employer_company_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="employerCompanyOptions"
                    :clearable="false"
                    label="company_name"
                    :reduce="(option) => option.id"
                    placeholder="Select Employer"
                  />
                  <feather-icon size="18" icon="ChevronDownIcon" />
                </div>
              </b-form-group>
            </b-col>
            <hr v-if="!userData.is_for_client" />

            <!-- Field: CE Number-->
            <b-col v-if="!userData.is_for_client" cols="12" md="6">
              <validation-provider
                #default="validationContext"
                name="CE Number"
                rules=""
              >
                <b-form-group
                  label-cols-sm="3"
                  label-cols="12"
                  class=""
                  label="CE Number"
                  label-for="ce_number"
                >
                  <b-form-input
                    id="ce_number"
                    v-model="userData.ce_number"
                    placeholder="Enter ce number"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <hr v-if="!userData.is_for_client" />
          <div
            ref="form"
            class="invoice-items repeater-form"
            :style="{ height: trHeight }"
            v-if="!userData.is_for_client"
          >
            <b-row
              v-for="(license, index) in userData.user_licenses"
              :key="`license_${index}`"
              ref="row"
              class=""
            >
              <!-- Field: License Number-->
              <b-col cols="12" class="mb-2">
                <div class="d-flex border rounded">
                  <b-row class="flex-grow-1 p-2">
                    <b-col cols="12" md="6">
                      <!-- <validation-provider
                        #default="validationContext"
                        :vid="'license_authority' + index"
                        name="License Authority"
                        rules="required"
                      > -->
                      <b-form-group
                        class=""
                        label-cols-sm="3"
                        label-cols="12"
                        :label="
                          index > 0
                            ? 'License Authority(' + index + ')'
                            : 'License Authority'
                        "
                        label-for="license_authority"
                      >
                        <div class="form-col-select">
                          <v-select
                            v-model="license.authority_id"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="licenseAuthorityOptions"
                            :clearable="true"
                            label="authority_name"
                            :reduce="(option) => option.id"
                            placeholder="Select SFC / IA"
                          />
                          <feather-icon size="18" icon="ChevronDownIcon" />
                          <!-- <b-form-invalid-feedback
                              :state="getValidationState(validationContext)"
                            >
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback> -->
                        </div>
                      </b-form-group>
                      <!-- </validation-provider> -->
                    </b-col>

                    <!-- Field: License Number(2)-->
                    <b-col cols="12" md="6">
                      <!-- <validation-provider
                        #default="validationContext"
                        name="License Number"
                        :vid="'license_number' + index"
                        rules="required"
                      > -->
                      <b-form-group
                        class=""
                        label-cols-sm="3"
                        label-cols="12"
                        :label="
                          index > 0
                            ? 'License Number(' + index + ')'
                            : 'License Number'
                        "
                        label-for="license_number"
                      >
                        <b-form-input
                          id="license_number"
                          v-model="license.license_number"
                          placeholder="Enter License Number"
                        />
                        <!-- :state="getValidationState(validationContext)" -->
                        <!-- <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback> -->
                      </b-form-group>
                      <!-- </validation-provider> -->
                    </b-col>
                    <!-- Field: License Type -->
                    <b-col cols="12" md="6">
                      <!-- <validation-provider
                        #default="validationContext"
                        name="License Type"
                        :vid="'license_type' + index"
                        rules="required"
                      > -->
                      <b-form-group
                        class=""
                        label-cols-sm="3"
                        label-cols="12"
                        :label="
                          index > 0
                            ? 'License Type(' + index + ')'
                            : 'License Type'
                        "
                        label-for="license_type"
                      >
                        <div class="form-col-select">
                          <v-select
                            v-model="license.license_type"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="
                              licenseTypeOptions.filter(
                                (x) =>
                                  x.license_authority_id == license.authority_id
                              )
                            "
                            :clearable="true"
                            label="license_type"
                            :reduce="(option) => option"
                            placeholder="Select RA1, RA4"
                            multiple
                          />
                          <feather-icon size="18" icon="ChevronDownIcon" />

                          <!-- <b-form-invalid-feedback
                              :state="getValidationState(validationContext)"
                            >
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback> -->
                        </div>
                      </b-form-group>
                      <!-- </validation-provider> -->
                    </b-col>

                    <!-- Field: Annual Return -->
                    <b-col cols="12" md="6">
                      <!-- <validation-provider
                        #default="validationContext"
                        name="Annual Return"
                        :vid="'annual_return' + index"
                        rules="required"
                      > -->
                      <b-form-group
                        class=""
                        label-cols-sm="3"
                        label-cols="12"
                        :label="
                          index > 0
                            ? 'Annual Return(' + index + ')'
                            : 'Annual Return'
                        "
                        label-for="annual_return"
                      >
                        <flat-pickr
                          v-model="license.annual_return"
                          class="form-control"
                          :config="flatDateConfig"
                          placeholder="YYYY-MM-DD"
                        />
                        <!-- <b-form-invalid-feedback
                            :state="getValidationState(validationContext)"
                          >
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback> -->
                      </b-form-group>
                      <!-- </validation-provider> -->
                    </b-col>
                  </b-row>
                  <div
                    class="d-flex flex-column justify-content-between border-left py-50 px-25"
                  >
                    <feather-icon
                      size="16"
                      icon="PlusIcon"
                      class="cursor-pointer"
                      @click="addLicense"
                      v-if="userData.user_licenses.length == 1"
                    />
                    <feather-icon
                      v-if="userData.user_licenses.length > 1"
                      size="16"
                      icon="XIcon"
                      class="cursor-pointer"
                      @click="removeLicense(index)"
                    />
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>

          <!-- Form: Personal Info Form -->
          <b-row class="mt-1">
            <b-col cols="12" md="12" v-if="userData.id == 0">
              <h4 class="mb-0">Password</h4>
              <hr />
            </b-col>
            <!-- Field: Password -->
            <b-col cols="12" md="6" v-if="userData.id == 0">
              <validation-provider
                #default="validationContext"
                name="Password"
                rules="required|min:8"
              >
                <b-form-group
                  label-cols-sm="3"
                  label-cols="12"
                  class="required"
                  label="Password"
                  label-for="password"
                >
                  <b-form-input
                    id="password"
                    type="password"
                    :state="getValidationState(validationContext)"
                    v-model="userData.password"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Field: Password Confirm -->
            <b-col cols="12" md="6" v-if="userData.id == 0">
              <validation-provider
                #default="validationContext"
                name="Password Confirm"
                rules="required|min:8|confirmed:Password"
              >
                <b-form-group
                  label-cols-sm="3"
                  label-cols="12"
                  class="required"
                  label="Password Confirm"
                  label-for="password_confirmation"
                >
                  <b-form-input
                    id="password_confirmation"
                    type="password"
                    :state="getValidationState(validationContext)"
                    v-model="userData.password_confirmation"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- <b-col cols="12" md="6" v-if="userData.id == 0">
            <validation-provider #default="validationContext" name="Role" rules="required">
              <b-form-group label-for="user-role">
                <v-select v-model="userData.roles" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="roleOptions" :clearable="false" label="name" />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col> -->
          </b-row>

          <!-- Button: Submit & Reset Button.-->

          <hr />
          <div class="d-flex mt-1 flex-wrap flex-sm-nowrap justify-content-end">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-sm-2 mobile-w100"
              type="submit"
            >
              <feather-icon size="16" class="mr-1" icon="CheckCircleIcon" />
              Submit
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
              class="mt-1 mt-sm-0 mobile-w100"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </b-overlay>
    </validation-observer>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BOverlay,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormCheckboxGroup,
  BButton,
  BFormCheckbox,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { ref, onMounted } from "@vue/composition-api";
import vSelect from "vue-select";
import store from "@/store";
import Ripple from "vue-ripple-directive";
import "animate.css";
import { heightTransition } from "@core/mixins/ui/transition";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import { required, alphaNum, email } from "@validations";

export default {
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  components: {
    BRow,
    BCol,
    BForm,
    BOverlay,
    BFormGroup,
    flatPickr,
    BFormInput,
    vSelect,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BButton,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
  },
  directives: {
    Ripple,
  },
  methods: {
    addLicense() {
      console.log("clicked");
      this.userData.user_licenses.push({
        authority_id: "",
        license_number: "",
        annual_return: "",
        license_type: "",
        user_license_id: this.userData.id == 0 ? 0 : 1,
      });
    },
    removeLicense(e) {
      console.log("remove", e);
      this.userData.user_licenses.splice(e, 1);
    },
    hide() {
      this.$router.replace("/users/list");
    },
    onSubmit() {
      // this.userData.team = [...this.userData.teams]
      console.log("submit data", this.userData);
      this.$swal({
        title: "Are you sure?",
        showCancelButton: true,
        confirmButtonText: "Save Changes",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;

          /*
          console.log(typeof this.userData.teams)
          if(typeof this.userData.teams == 'object')
          {
            this.userData.team_id = this.userData.teams[0].id
          }else{
            this.userData.team_id = this.userData.teams
          }
          */
          // var name = this.userData.roles.name
          // if (!name) name = this.userData.roles[0].name

          // this.userData.role_name = name

          store
            .dispatch("app-user/updateUser", this.userData)
            .then((response) => {
              this.loading = false;

              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.$router.replace("/users/list");
              // this.$emit("refetch-data");
              // setTimeout(() => {
              //   this.setLicense_type()
              // },500)
            })
            .catch((error) => {
              this.loading = false;

              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
  },
  initTrHeight() {
    this.trSetHeight(null);
    this.$nextTick(() => {
      this.trSetHeight(this.$refs.form.scrollHeight);
    });
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    console.log(props.userData);
    const setLicense_type = () => {
      if (props.userData.id > 1) {
        console.log("ff");
        props.userData.user_licenses = props.userData.user_licenses.map(
          (item) => {
            console.log("dd", props.userData);
            item.license_type = props.userData.license_types.filter((x) => {
              return x.license_authority_id == item.authority_id;
            });
            return {
              id: item.id,
              license_authority_id: item.license_authority_id,
              license_type: item.license_type,
            };
          }
        );
      }
    };

    const loading = ref(false);

    const positionOptions = ref([]);
    const teamOptions = ref([]);
    const genderOptions = ref([]);
    const licenseAuthorityOptions = ref([]);
    const licenseTypeOptions = ref([]);
    const userStatusOptions = ref([]);
    const employerCompanyOptions = ref([]);

    const visaOptions = [
      { text: "N/A", value: 0 },
      { text: "Yes", value: 1 },
    ];

    const contactOptionsOptions = ["Email", "Message", "Phone"];

    const resetData = () => {
      emit("refetch-data");
    };
    const resetuserData = () => {
      props.clientData = JSON.parse(JSON.stringify({}));
    };
    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetuserData);

    const fetchPositionData = () => {
      // store
      //   .dispatch("app-user/fetchPositions")
      //   .then((response) => {
      //     positionOptions.value = response.data.positions;
      //   })
      //   .catch((error) => {
      //     if (error.response.status === 404) {
      //       positionOptions.value = undefined;
      //     }
      //   });
    };

    const fetchTeamData = () => {
      store
        .dispatch("app-user/fetchTeams")
        .then((response) => {
          teamOptions.value = response.data.teams;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            teamOptions.value = undefined;
          }
        });
    };

    const fetchGenderData = () => {
      store
        .dispatch("app-user/fetchGenders")
        .then((response) => {
          genderOptions.value = response.data.genders;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            genderOptions.value = undefined;
          }
        });
    };

    const fetchLicenseAuthorityData = () => {
      store
        .dispatch("app-user/fetchLicenseAuthorities")
        .then((response) => {
          licenseAuthorityOptions.value = response.data.license_authorities;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            licenseAuthorityOptions.value = undefined;
          }
        });
    };

    const fetchLicenseTypeData = () => {
      store
        .dispatch("app-user/fetchLicenseTypes")
        .then((response) => {
          licenseTypeOptions.value = response.data.license_types;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            licenseTypeOptions.value = undefined;
          }
        });
    };

    const fetchUserStatusData = () => {
      store
        .dispatch("app-user/fetchUserStatus")
        .then((response) => {
          console.log(response);
          userStatusOptions.value = response.data.user_statuses;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            userStatusOptions.value = undefined;
          }
        });
    };

    const fetchEmployerCompanyData = () => {
      store
        .dispatch("app-user/fetchEmployerCompany")
        .then((response) => {
          employerCompanyOptions.value = response.data.employer_companies;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            employerCompanyOptions.value = undefined;
          }
        });
    };

    const roleOptions = ref([]);

    const fetchRoleOptions = () => {
      store
        .dispatch("app-user/fetchRoles")
        .then((response) => {
          roleOptions.value = response.data.roles;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            teamOptions.value = undefined;
          }
        });
    };

    onMounted(() => {
      fetchPositionData();
      fetchRoleOptions();
      fetchTeamData();
      fetchGenderData();
      fetchLicenseAuthorityData();
      fetchLicenseTypeData();
      fetchUserStatusData();
      fetchEmployerCompanyData();
      console.log(props.userData);
      setLicense_type();
    });

    return {
      loading,
      genderOptions,
      visaOptions,
      contactOptionsOptions,
      positionOptions,
      teamOptions,
      roleOptions,
      employerCompanyOptions,
      licenseAuthorityOptions,
      licenseTypeOptions,
      userStatusOptions,
      resetData,
      refFormObserver,
      getValidationState,
      resetForm,
      resetuserData,
      setLicense_type,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.teamleader_selected {
  background: #110f6a;
  padding: 3px 10px;
  border-radius: 3px;
  color: white;
}

.addParent {
  position: relative;
}

.addChildren {
  position: absolute;
  right: -5px;
  bottom: 35%;
  padding: 2px !important;
  background: #013e79;
  border-radius: 15px;
  color: white;
}

.removeChildren {
  position: absolute;
  right: -5px;
  bottom: 35%;
  padding: 2px !important;
  background: #d14f4f;
  border-radius: 15px;
  color: white;
}
</style>
