import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import { title } from "@core/utils/filter";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ability from "@/libs/acl/ability";

export default function useUsersList() {
  // Use toast
  const toast = useToast();

  const refUserListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: "id", label: "User ID", sortable: true },
    { key: "name", stickyColumn: true, label: "User Name (EN)", sortable: true },
    { key: "name_cn", label: "User Name (CN)", sortable: true },
    { key: "license_number", label: "License Number" },
    { key: "license_types", label: "License Type" },
    { key: "annual_return", label: "Annual Return" },
    { key: "email", sortable: true },
    { key: "working_visa", label: "Working Visa", sortable: true }, //  == 0 ? "N/A" : "Yes" in template userlists
    { key: "user_statuses_id", label: "Status", sortable: true },
    // { key: "role", label: "ROLE" },
    // { key: "position_id", label: "Position", sortable: true },
    // { key: "team_id", label: "Team", sortable: true },
    // { key: "is_teamleader", label: "Team Leader", sortable: true },
    // { key: "is_active", label: "Enable" },
    { key: "actions" },
  ];
  const perPage = ref(10);
  const totalUsers = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const roleFilter = ref(null);
  const planFilter = ref(null);
  const statusFilter = ref(null);
  const isClientFilter = ref('All');
  const isForClientFilter = ref(['All', 'Client', 'User']);

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value
      ? refUserListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    };
  });

  const refetchData = () => {
    console.log('refUserListTable', refUserListTable)
    refUserListTable.value.refresh();
  };

  watch(
    [currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter, isClientFilter],
    () => {
      refetchData();
    }
  );

  const fetchUsers = (ctx, callback) => {
    store
      .dispatch("app-user/fetchUsers", {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        role: roleFilter.value,
        plan: planFilter.value,
        status: statusFilter.value,
        is_for_client: isClientFilter.value == 'All' ? null : isClientFilter.value == 'Client' ? '1' : '-1',
      })
      .then((response) => {
        console.log(response)
        const { users, total } = response.data;
        callback(users);
        totalUsers.value = total;
      })
      .catch((err) => {
        console.log(err);
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching users list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = (role) => {
    if (role === "subscriber") return "primary";
    if (role === "author") return "warning";
    if (role === "maintainer") return "success";
    if (role === "editor") return "info";
    if (role === "admin") return "danger";
    return "primary";
  };

  const resolveUserRoleIcon = (role) => {
    if (role === "subscriber") return "UserIcon";
    if (role === "author") return "SettingsIcon";
    if (role === "maintainer") return "DatabaseIcon";
    if (role === "editor") return "Edit2Icon";
    if (role === "admin") return "ServerIcon";
    return "UserIcon";
  };

  const resolveUserStatusVariant = (status) => {
    if (status === "pending") return "warning";
    if (status === "active") return "success";
    if (status === "inactive") return "secondary";
    return "primary";
  };

  return {
    fetchUsers,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,
    ability,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    refetchData,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
    isForClientFilter,
    isClientFilter,
  };
}
